import * as React from "react";
import styled from "styled-components";
import { Button } from "semantic-ui-react";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 5px;
  margin-bottom: 10px;
`;

const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  cursor: pointer;
  background: ${(props) => (props.negative ? "red" : "gray")};
  border-radius: 3px;
  color: white;
  font-size: 20pt;
`;

const NumericalValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0;
`;

const NumericalValue = styled.div`
  text-align: right;
  font-family: monospace;
  font-size: 25pt;
  line-height: normal;
`;

const ExistingNumericalValue = styled.div`
  text-align: right;
  font-family: monospace;
  font-size: 17pt;
  line-height: normal;
`;

const NUMBERS = [9, 8, 7, 6, 5, 4, 3, 2, 1];

export default function NumberPad({
  onChange,
  existingValue,
  onButtonClick,
  buttonDisabled,
  buttonLoading,
  handleSkip,
  buttonText = "save",
}) {
  const [value, setValue] = React.useState([]);
  const [negative, setNegative] = React.useState(false);

  const displayValue = React.useMemo(() => {
    const val = value.reduce((prev, curr) => prev + `${curr}`, "");
    if (val) {
      const factor = negative ? -1 : 1;
      return val * factor;
    } else {
      return 0;
    }
  }, [value, negative]);

  React.useEffect(() => {
    onChange(displayValue);
  }, [onChange, displayValue]);

  return (
    <div>
      <NumericalValueWrapper>
        <ExistingNumericalValue>
          {existingValue !== null && <span>({existingValue})</span>}
        </ExistingNumericalValue>
        <NumericalValue>{displayValue}</NumericalValue>
      </NumericalValueWrapper>
      <Wrapper>
        {NUMBERS.map((number) => (
          <Number
            key={`n-${number}`}
            onClick={() => setValue((v) => [...v, number])}
          >
            {number}
          </Number>
        ))}
        <Number negative={negative} onClick={() => setNegative((n) => !n)}>
          -
        </Number>
        <Number onClick={() => setValue((v) => [...v, 0])}>0</Number>
        <Number
          onClick={() =>
            setValue((v) => {
              return v.slice(0, v.length - 1);
            })
          }
        >
          &larr;
        </Number>
      </Wrapper>
      <div style={{ display: "flex" }}>
        <Button onClick={handleSkip}>Skip</Button>
        <Button
          content={buttonText}
          fluid
          size="huge"
          color="green"
          disabled={buttonDisabled}
          loading={buttonLoading}
          onClick={async () => {
            await onButtonClick();
            setValue([]);
          }}
        />
      </div>
    </div>
  );
}
